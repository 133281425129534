const Options = {
  prosody: [
    {
      title: "검색",
      route: "search",
      children: [{ title: "전체 검색", route: "all" }],
    },
    {
      title: "학습",
      route: "learning",
      children: [
        { title: "신규 학습", route: "create" },
        { title: "추가 학습", route: "update" },
      ],
    },
    {
      title: "목소리 생성",
      route: "voice",
      children: [
        { title: "기본", route: "default" },
        { title: "감정", route: "sts" },
      ],
    },
  ],
  clova: [
    {
      title: "전체",
      route: "split",
      children: [{ title: "분할", route: "" }],
    },
  ],
};

export default Options;
