import React from "react";

import SiderOptions from "@src/configs/siderOptions";
import DefaultTemplate from "@templates/defaultTemplate";
import "./App.css";
import { BrowserRouter } from "react-router-dom";

import Routes from "./routes";

const App: React.FC = () => (
  <BrowserRouter>
    <DefaultTemplate siderOptions={SiderOptions} content={<Routes />} />
  </BrowserRouter>
);

export default App;
