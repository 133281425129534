import React, { useEffect, useState } from "react";

import { Table } from "antd";
import axios from "axios";
import { VList } from "virtual-table-ant-design";

const SearchList: React.FC = () => {
  const [data, setData] = useState();
  const [isLoading, setisLoading] = useState(true);

  const getData = async () => {
    const { data: apiData } = await axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}/voice/search/all-list`
    );
    setData(apiData);
    setisLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  const columns = [
    {
      title: "uid",
      dataIndex: "uid",
      key: "uid",
      width: 500,
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "language",
      dataIndex: "language",
      key: "language",
    },
    {
      title: "gender",
      dataIndex: "gender",
      key: "gender",
      render: (text: string) => <span>{text === "m" ? "남자" : "여자"}</span>,
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="uid"
      pagination={{ pageSize: 100 }}
      scroll={{ y: 400 }}
      bordered
      loading={isLoading}
      components={VList({
        height: 500,
      })}
    />
  );
};

export default SearchList;
