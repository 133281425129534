import React from "react";

import SplitMedia from "@pages/clova";
import { LearningCreate, LearningUpdate, SearchList } from "@pages/prosody";
import VoiceDefault from "@pages/prosody/VoiceDefault";
import VoiceSts from "@pages/prosody/VoiceSts";
import { Navigate, Route, Routes } from "react-router-dom";

const CustomRoutes: React.FC = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/prosody/search/all" />} />
    <Route path="/prosody">
      <Route path="/prosody/search/all" element={<SearchList />} />
      <Route path="/prosody/learning/create" element={<LearningCreate />} />
      <Route path="/prosody/learning/update" element={<LearningUpdate />} />
      <Route path="/prosody/voice/default" element={<VoiceDefault />} />
      <Route path="/prosody/voice/sts" element={<VoiceSts />} />
    </Route>
    <Route path="/clova">
      <Route path="/clova/split" element={<SplitMedia />} />
    </Route>
  </Routes>
);

export default CustomRoutes;
