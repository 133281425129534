import React, { useEffect, useState } from "react";

import { Layout, Menu, Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import ISiderOption from "src/@types/siderOptions";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
interface DefaultTemplateProps {
  content: React.ReactNode;
  siderOptions: { [key: string]: ISiderOption[] };
}

const headerRoutes = [
  { title: "Prosody", route: "prosody", defaultRoute: "prosody/search/all" },
  { title: "Clova STT + Prosody", route: "clova", defaultRoute: "clova/split" },
  {
    title: "Clova + Prosody",
    route: "clovaProsody",
    defaultRoute: "clovaProsody",
  },
];

const DefaultTemplate: React.FC<DefaultTemplateProps> = ({
  content,
  siderOptions,
}) => {
  const { pathname } = useLocation();
  const [breadcrumbs, setBreadCrumbs] = useState<string[]>([]);
  const [headerRoute, setHeaderRoute] = useState<string | undefined>(undefined);
  const [sider, setSider] = useState<ISiderOption[]>([]);

  useEffect(() => {
    let splitedPathname = pathname
      .split("/")
      .filter((path) => path !== "/")
      .filter((path) => path !== "");

    if (splitedPathname.length === 0) {
      splitedPathname = ["prosody", "search", "all"];
    }
    const siderOptionsRouteTitle: string[] = [];
    const headerInfo = headerRoutes.filter(
      ({ route }) => route.indexOf(splitedPathname[0]) === 0
    )[0];
    setHeaderRoute(headerInfo.route);
    if (headerRoute) {
      sider.some((obj) => {
        if (obj.route === splitedPathname[1]) {
          siderOptionsRouteTitle.push(obj.title);
          if (
            obj.children.filter((ch) => ch.route === splitedPathname[2]).length
          ) {
            siderOptionsRouteTitle.push(
              obj.children.filter((ch) => ch.route === splitedPathname[2])[0]
                .title
            );
          }
          return true;
        }
        return false;
      });
      setBreadCrumbs([headerInfo.title, ...siderOptionsRouteTitle]);
      setSider(siderOptions[headerRoute]);
    }

    return () => {
      setSider([]);
      setBreadCrumbs([]);
    };
  }, [headerRoute, pathname, sider, siderOptions]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="header">
        <div className="logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={breadcrumbs}
          defaultSelectedKeys={breadcrumbs}
        >
          {headerRoutes.map((obj) => (
            <Menu.Item key={obj.title}>
              <Link key={obj.title} to={obj.defaultRoute}>
                {obj.title}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[
              pathname.split("/").slice(1).join("/"),
              `${pathname.split("/").slice(1).join("/")}/`,
            ]}
            openKeys={sider.map(({ title }) => title)}
            style={{ height: "100%", borderRight: 0 }}
          >
            {sider.map((siderOption) => (
              <SubMenu key={siderOption.title} title={siderOption.title}>
                {siderOption.children.map(({ title, route }) => (
                  <Menu.Item
                    key={`${headerRoute}/${siderOption.route}/${route}`}
                  >
                    <Link to={`${headerRoute}/${siderOption.route}/${route}`}>
                      {title}
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            {breadcrumbs.map((breadcrumb) => (
              <Breadcrumb.Item key={breadcrumb}>{breadcrumb}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {content}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DefaultTemplate;
