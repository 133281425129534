import React, { useCallback, useState } from "react";

import { UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Form,
  InputNumber,
  Select,
  Spin,
  Upload,
} from "antd";
import axios from "axios";
import _ from "lodash";

const LearningUpdate: React.FC = () => {
  const [reqStatus, setReqStatus] = useState<
    "success" | "process" | "error" | undefined
  >(undefined);
  const [selectOptions, setSelectOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [defaultValue] = useState({
    name: "",
    gender: "m",
    age: 20,
    region: "Korea",
    language: "Korean",
    wavs: undefined,
    csv: undefined,
  });

  const [form] = Form.useForm();

  const checkUserName = useCallback(async (what: any, value: string) => {
    if (value === "") {
      return Promise.reject(new Error(""));
    }

    const { data: apiData } = await axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}/voice/search/user/${value}`
    );
    const hasUserName = apiData.some(
      (speaker: { name: string }) => speaker.name === value
    );
    if (!hasUserName) {
      return Promise.reject(new Error("없는 화자명입니다."));
    }
    return Promise.resolve();
  }, []);
  const checkFileList = useCallback(async (what: any, value) => {
    if (!value) {
      return Promise.reject(new Error("파일을 첨부!"));
    }
    if (!value.file) {
      return Promise.reject(new Error("파일을 첨부!"));
    }
    if (value.fileList.length === 0) {
      return Promise.reject(new Error("파일을 첨부!"));
    }
    return Promise.resolve();
  }, []);

  const beforeUpload = useCallback(() => false, []);
  const getOptions = useCallback(async (value) => {
    if (value.trim() === "") {
      setSelectOptions([]);
      return;
    }

    setFetching(true);
    const { data: apiData } = await axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}/voice/search/user/${value}`
    );
    setFetching(false);
    const newOptions = apiData.map((obj: { uid: string; name: string }) => ({
      value: obj.uid,
      label: obj.name,
      ...obj,
    }));
    setSelectOptions(newOptions);
  }, []);

  const onSelect = useCallback(
    (selectValue) => {
      const selectOption = selectOptions.filter(
        ({ value }) => value === selectValue
      )[0];
      form.setFieldsValue(selectOption);
    },
    [form, selectOptions]
  );
  const onFinish = useCallback(async (values: any) => {
    setReqStatus("process");

    const { name, wavs, xls } = values;
    const formData = new FormData();

    Array.from(wavs.fileList).forEach((obj, index) => {
      formData.append("wavs", wavs.fileList[index].originFileObj);
    });
    formData.append("csv", xls.file);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_API_URL}/voice/learn/update/${name}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        if (data.success) {
          setReqStatus("success");
          setTimeout(() => {
            setReqStatus(undefined);
          }, 2000);
        }
      })
      .catch(() => {
        setReqStatus("error");
        setTimeout(() => {
          setReqStatus(undefined);
        }, 4000);
      });
  }, []);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="customized_form_controls"
        onFinish={onFinish}
        initialValues={defaultValue}
      >
        <Col span={12}>
          <Form.Item
            name="name"
            label="화자명"
            rules={[
              { required: true, message: "화자명 필수" },
              { validateTrigger: "onBlur", validator: checkUserName },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              showSearch
              filterOption={false}
              onSearch={_.debounce(getOptions, 300)}
              options={selectOptions}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              onSelect={onSelect}
              // onChange={test}

              // onChange={() => _.debounce(test, 2000)}
            >
              {selectOptions.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
            <Col span={12} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="gender"
            label="성별"
            rules={[{ required: true, message: "성별 필수" }]}
          >
            <Select disabled>
              <Select.Option value="m">남자</Select.Option>
              <Select.Option value="f">여자</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="age"
            label="나이"
            rules={[{ required: true, message: "나이 필수" }]}
          >
            <InputNumber min={10} max={80} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="region"
            label="국가"
            rules={[{ required: true, message: "국가 필수" }]}
          >
            <Select disabled>
              <Select.Option value="Korea">[근본] 한국</Select.Option>
              <Select.Option value="United States">United States</Select.Option>
              <Select.Option value="United Kingdom">
                United Kingdom
              </Select.Option>
              <Select.Option value="China">China</Select.Option>
              <Select.Option value="Japan">Japan</Select.Option>
              <Select.Option value="Spain">Spain</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="language"
            label="화자의 언어"
            rules={[{ required: true, message: "나이 필수" }]}
          >
            
            <Select disabled>
              <Select.Option value="Korean">[근본] 한국어</Select.Option>
              <Select.Option value="English">English</Select.Option>
              <Select.Option value="Chinese">Chinese</Select.Option>
              <Select.Option value="Japanese">Japanese</Select.Option>
              <Select.Option value="Spanish">Spanish</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="wavs"
            label="wavs"
            rules={[
              { required: true, message: "Wav 필수" },
              { validator: checkFileList },
            ]}
          >
            <Upload beforeUpload={beforeUpload} multiple accept="audio/*">
              <Button icon={<UploadOutlined />}>Wav</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="xls"
            label="엑셀"
            rules={[
              { required: true, message: "엑셀 필수" },
              { validator: checkFileList },
            ]}
          >
            <Upload
              beforeUpload={beforeUpload}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              <Button icon={<UploadOutlined />}>엑셀</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={!!reqStatus}>
              추가 학습
            </Button>
          </Form.Item>
        </Col>
      </Form>
      {reqStatus === "success" && (
        <Alert message="저장 성공" type="success" showIcon />
      )}
      {reqStatus === "error" && (
        <Alert message="저장실패~!" type="error" showIcon />
      )}
    </>
  );
};

export default LearningUpdate;
