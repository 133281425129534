import React, { useCallback, useState } from "react";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";

const SplitMedia: React.FC = () => {
  const [fileList, setFileList] = useState<FileList[]>([]);

  const beforeUpload = useCallback(() => false, []);
  const onRemove = useCallback(
    (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    [fileList]
  );

  return (
    <div style={{ flexDirection: "column" }}>
      <div style={{ display: "flex" }}>
        <Upload
          // beforeUpload={beforeUpload}
          // action="${process.env.REACT_APP_SERVER_API_URL}/stt/split/"
          accept="video/*,audio/*"
          onRemove={onRemove}
        >
          <Button icon={<UploadOutlined />}>미디어 업로드</Button>
        </Upload>
      </div>
    </div>
  );
};

export default SplitMedia;
