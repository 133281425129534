import React, { useCallback, useState } from "react";

import { UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Upload,
} from "antd";
import axios from "axios";
import _ from "lodash";

const VoiceSts: React.FC = () => {
  const [reqStatus, setReqStatus] = useState<
    "success" | "process" | "error" | undefined
  >(undefined);
  const [selectOptions, setSelectOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [defaultValue] = useState({
    name: "",
    emotion: "Neutral",
    gender: "",
    language: "Korea",
    pitch: 1,
    text: "",
    wav: undefined,
  });

  const emotions = [
    { label: "Angry", value: "Angry" },
    { label: "Calm", value: "Calm" },
    { label: "Disappointed", value: "Disappointed" },
    { label: "Excited", value: "Excited" },
    { label: "Fear", value: "Fear" },
    { label: "Happy", value: "Happy" },
    { label: "Neutral", value: "Neutral" },
    { label: "sLeepy", value: "sLeepy" },
    { label: "Sad", value: "Sad" },
  ];

  const [form] = Form.useForm();

  const checkUserName = useCallback(async (what: any, value: string) => {
    if (value === "") {
      return Promise.reject(new Error(""));
    }

    const { data: apiData } = await axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}/voice/search/user/${value}`
    );
    const hasUserName = apiData.some(
      (speaker: { name: string }) => speaker.name === value
    );
    if (!hasUserName) {
      return Promise.reject(new Error("없는 화자명입니다."));
    }
    return Promise.resolve();
  }, []);

  const checkFileList = useCallback(async (what: any, value) => {
    if (!value || !value.file || value.fileList.length === 0) {
      return Promise.reject(new Error("파일을 첨부!"));
    }
    return Promise.resolve();
  }, []);

  const beforeUpload = useCallback(() => false, []);

  const getOptions = useCallback(async (value) => {
    if (value.trim() === "") {
      setSelectOptions([]);
      return;
    }
    setFetching(true);

    const { data: apiData } = await axios.get(
      `${process.env.REACT_APP_SERVER_API_URL}/voice/search/user/${value}`
    );
    setFetching(false);

    const newOptions = apiData.map((obj: { uid: string; name: string }) => ({
      value: obj.uid,
      label: obj.name,
      ...obj,
    }));
    setSelectOptions(newOptions);
  }, []);

  const onSelect = useCallback(
    (selectValue) => {
      const selectOption = selectOptions.filter(
        ({ value }) => value === selectValue
      )[0];
      form.setFieldsValue(selectOption);
    },
    [form, selectOptions]
  );
  const onFinish = useCallback(async (values: any) => {
    setReqStatus("process");

    const { name, wav, emotion, text, language, pitch } = values;
    const formData = new FormData();

    formData.append("actor", name);
    formData.append("emotion", emotion);
    formData.append("text", text);
    formData.append("language", language);
    formData.append("pitch", pitch);
    formData.append("wav", wav.file);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_API_URL}/voice/generate/sts`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(({ data }) => {
        if (data.success) {
          console.log(data.data);
          console.log(window.open(data.data.pre_signed_url, "_blank"));
          setReqStatus("success");
          setTimeout(() => {
            setReqStatus(undefined);
          }, 2000);
        }
      })
      .catch(() => {
        setReqStatus("error");
        setTimeout(() => {
          setReqStatus(undefined);
        }, 4000);
      });
  }, []);

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="customized_form_controls"
        onFinish={onFinish}
        initialValues={defaultValue}
      >
        <Col span={12}>
          <Form.Item
            name="name"
            label="화자명"
            rules={[
              { required: true, message: "화자명 필수" },
              { validateTrigger: "onBlur", validator: checkUserName },
            ]}
          >
            <Select
              style={{ minWidth: 100 }}
              showSearch
              filterOption={false}
              onSearch={_.debounce(getOptions, 500)}
              options={selectOptions}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              onSelect={onSelect}
            >
              {selectOptions.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="gender"
            label="성별"
            rules={[{ required: true, message: "성별 필수" }]}
          >
            <Select disabled style={{ minWidth: 70 }}>
              <Select.Option value="m">남자</Select.Option>
              <Select.Option value="f">여자</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="emotion"
            label="감정"
            rules={[{ required: true, message: "감정 필수" }]}
          >
            <Select>
              {emotions.map((obj) => (
                <Select.Option key={obj.value} value={obj.value}>
                  {obj.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="language"
            label="변환할 언어"
            rules={[{ required: true, message: "나이 필수" }]}
          >
            <Select>
              <Select.Option value="Korea">[근본] 한국어</Select.Option>
              <Select.Option value="United States">United States</Select.Option>
              <Select.Option value="United Kingdom">
                United Kingdom
              </Select.Option>
              <Select.Option value="China">China</Select.Option>
              <Select.Option value="Spain">Spain</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="pitch"
            label="pitch"
            rules={[{ required: true, message: "pitch 필수" }]}
          >
            <InputNumber
              style={{ width: 100 }}
              min="0"
              max="2"
              step="0.01"
              stringMode
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="text"
            label="text"
            rules={[{ required: true, message: "text 필수" }]}
          >
            <Input.TextArea style={{ width: "400px" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="wav"
            label="wav"
            valuePropName="wav"
            rules={[{ validator: checkFileList }]}
          >
            <Upload beforeUpload={beforeUpload} accept="audio/*">
              <Button icon={<UploadOutlined />}>Wav</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={!!reqStatus}>
              요청
            </Button>
          </Form.Item>
        </Col>
      </Form>
      {reqStatus === "success" && (
        <Alert message="저장 성공" type="success" showIcon />
      )}
      {reqStatus === "error" && (
        <Alert message="저장실패~!" type="error" showIcon />
      )}
    </>
  );
};

export default VoiceSts;
